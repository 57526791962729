import React,{useState} from 'react';
import { Routes , Route } from "react-router";
import MainPage from "./components/MainPage/MainPage";
import "./App.css";
import News from "./components/News/News";
import Media from "./components/Media/Media";
import Oyuntasarim from "./components/MainPage/Oyuntasarim";
import Espor from "./components/MainPage/Espor";
import YeniNesilOgrenme from "./components/MainPage/YeniNesilOgrenme";
import Youtube from "./components/MainPage/Youtube";
import NewsDetail from "./components/News/NewsDetail";
//import 'bootstrap/dist/css/bootstrap.min.css'; 

import Project from "./components/Project/Project";
import Mega from "./components/Mega/Mega";

import PdfPage from "./components/MainPage/PdfPage";

import KVKKPage from "./components/KVKK";


import Hakkimizda from "./components/Hakkimizda/Hakkimizda"
import UserPoolHistory from "./components/UserPoolHistory/UserPoolHistory"
import UserEducationHistory from "./components/UserPoolHistory/UserEducationHistory"

import KVKK from './KVKKModal';

import SSS from "./components/SSS/SSS";
import Team from "./components/Team/Team";
import Event from "./components/Event/Event";
import Contact from "./components/Contact/Content";

import 'moment/locale/tr';

function App() {
  const [showKVKK, setShowKVKK] = React.useState(true);

  React.useEffect(() => {
  async function _getCheck() {
    try{    
      const KVKKCheck =  JSON.parse(localStorage.getItem('KVKKCheck'))
      if(KVKKCheck==null){
        setShowKVKK(true)
      }else{
        setShowKVKK(false)
      }
   }
   catch (error) {
     
     console.log("errorAS" + error)
   }
}

_getCheck()
}, [])
  



const _closeKVKKVisible = () => {
setShowKVKK(false)
localStorage.setItem('KVKKCheck', true);
}
  return (
    <div>
    <Routes>

       <Route path="/" element={<MainPage/>}/>
       <Route path="oyuntasarim" element={<Oyuntasarim/>}/>
       <Route path="espor" element={<Espor/>}/>
       <Route path="youtube" element={<Youtube/>}/>
       <Route path="yeninesilogrenme" element={<YeniNesilOgrenme/>}/>
       <Route path="media" element={<Media/>}/>
       <Route path="event" element={<Event/>}/>
       <Route path="mega/:id" element={<Mega/>}/>
       <Route path="news" element={<News/>}/>
       <Route path="newsdetail/:id" element={<NewsDetail/>}/>
      <Route path="project/:id" element={<Project/>}/>
      <Route path="PdfPage" element={<PdfPage/>}/>

      <Route path="hakkimizda" element={<Hakkimizda/>}/>
      <Route path="sss" element={<SSS/>}/>
      <Route path="ekibimiz" element={<Team/>}/>
      <Route path="iletisim" element={<Contact/>}/>

      <Route  path="/kvkk" element={<KVKKPage />} />

      
    </Routes>

{showKVKK == true ? <KVKK openKVKK={showKVKK} _closeKVKKVisible={_closeKVKKVisible}/> : null}
</div>
  );
}

export default App;
