import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Document, Page, pdfjs } from "react-pdf";
import Navbar2 from "../Navbar2";
import axios from "axios";

// PDF.js Worker dosyasını ayarla
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

// Modal için root elementini belirtin
Modal.setAppElement("#root");

const PdfViewerWithModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [getData, setData] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    axios
      .get("https://getjob.stechomeyazilim.info:5101/getPdfMega/select/")
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  // Modalı açma
  const openModal = (pdfUrl) => {
    setSelectedPdfUrl(pdfUrl);
    setIsModalOpen(true);
  };

  // Modalı kapatma
  const closeModal = () => {
    setSelectedPdfUrl(null);
    setIsModalOpen(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  return (
    <div className="w-full h-full bg-gray-100">
      {!isLoading && <Navbar2 />}
      <div className="bg-gray-50 w-full h-screen pt-24 px-6 lg:px-16">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">PDF Listesi</h2>
        <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
    {getData.map((pdf) => (
        <li key={pdf.ID} className="flex justify-center">
            <div className="w-full max-w-xs p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out border border-gray-200">
                <h3 className="text-lg font-semibold text-gray-800 mb-2 text-center truncate">
                    {pdf.PDF_Name}
                </h3>
                <p className="text-sm text-gray-500 text-center mb-4">
                    PDF dökümanı
                </p>
                <button
                    className="w-full py-2 px-4 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
                    onClick={() => openModal(`https://megaadmin.stechomeyazilim.info:8080/get/${pdf.PDF_Link}`)}
                >
                    Görüntüle
                </button>
            </div>
        </li>
    ))}
</ul>


<Modal
    isOpen={isModalOpen}
    onRequestClose={closeModal}
    style={{
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "1000",
        },
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            padding: "0",
            overflow: "hidden",
            boxShadow: "0 4px 14px rgba(0, 0, 0, 0.2)",
            display: "flex",
            flexDirection: "column",
        },
    }}
>
    <div className="relative w-full h-full flex flex-col">
        <button
            onClick={closeModal}
            className="absolute top-4 right-4 bg-red-600 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-md hover:bg-red-700 transition duration-300 ease-in-out z-10"
        >
            ✕
        </button>
        {selectedPdfUrl && (
            <>
                <div className="flex-1 overflow-auto relative flex justify-center items-center">
                    <Document
                        file={selectedPdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        renderMode="canvas" // Metin katmanını devre dışı bırakır
                               
                    >
                        <Page
                          renderTextLayer={false} // Metin katmanı devre dışı
                          renderAnnotationLayer={false} // Anotasyon katmanı devre dışı
                          loading={<p className="text-lg text-blue-500 font-semibold">PDF Yükleniyor, lütfen bekleyin...</p>} // Özelleştirilmiş yüklenme mesajı
                                    pageNumber={pageNumber}
                            height={Math.min(window.innerHeight - 110)} // Genişlik modal içeriğine göre ayarlanır
                        />
                    </Document>
                </div>
                <div className="flex justify-between items-center p-4 border-t border-gray-300 bg-gray-100">
                    <button
                        onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
                        disabled={pageNumber <= 1}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Önceki
                    </button>
                    <p className="text-lg font-medium">
                        Sayfa {pageNumber} / {numPages}
                    </p>
                    <button
                        onClick={() => setPageNumber((prev) => Math.min(prev + 1, numPages))}
                        disabled={pageNumber >= numPages}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Sonraki
                    </button>
                </div>
            </>
        )}
    </div>
</Modal>



      </div>
    </div>
  );
};

export default PdfViewerWithModal;
