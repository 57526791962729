import React, { useEffect } from "react";
import Logo from "../logo2.png";
import whiteLogo from "../logo2.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import Autharizatiom from "./MainPage/LogIn/Autharizatiom";
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import {  faBullhorn, faCertificate, faHome, faImage, faInfo, faPhone,  faRightFromBracket, faRightToBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer, inject } from 'mobx-react'
import { isMobile } from "react-device-detect";
import { HiMenuAlt3, HiX } from 'react-icons/hi';

import Profile from "./Profile";

const Navbar = inject()(observer((props) => {
  const navigate = useNavigate()
  const location = useLocation();  // Yönlendirme sonrası mevcut sayfayı izlemek için

  const [nav, setNav] = useState(true);
  const handleNav = () => {
    setNav(!nav);
  };

  const [selectedMenu, setSelectedMenu] = useState(''); // Başlangıçta seçili menü boş

  const [signUpModal, setsignUpModal] = useState(false);
  const [logInModal, setLogInModal] = useState(false);


  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };


  const [checkDisplayMenu, setDisplayMenu] = useState(false);


  const hideDropdownMenu = () => {
    setDisplayMenu(!checkDisplayMenu)
    document.querySelector('#dropdown').removeEventListener('click', hideDropdownMenu);

  }


  const handleLogIn = () => {
    setLogInModal(false);
  };


  const _deleteAuth = async () => {
    localStorage.removeItem('dataLogin');
    localStorage.removeItem('dataLoginEpilepsi');

    //window.location.reload(false);
    navigate("/")
    _getData()

    window.location.reload(false);

  }



  const _closeAuthModal = async () => {
    setLogInModal(false)
  }


  const _closeLoginOpenSingUpModal = async () => {
    setLogInModal(false)
    setsignUpModal(true);
  }



  const openProfile = async () => {
    setProfileVisible(true);
  }


  const [getMega, setMega] = useState([]);
  const [getProject, setProject] = useState([]);
  const [getProfileVisible, setProfileVisible] = useState(false);


  const [loginData, setLoginData] = useState(null);

  const _closeProfileModal = async () => {
    setProfileVisible(false)
  }


  useEffect(() => {

    axios.get('https://getjob.stechomeyazilim.info:5101/getMegaMega/select/')
      .then(res => {
        setMega(res.data);

      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {

    axios.get('https://getjob.stechomeyazilim.info:5101/getProjectMegaWeb/select/')
      .then(res => {
        console.log("şlsmdlfş",res.data)
        setProject(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {


    const _getList = async () => {


      try {
        if (JSON.parse(localStorage.getItem('dataLogin')) != null) {
          setLoginData(JSON.parse(localStorage.getItem('dataLogin')))
        }

      }
      catch (error) {
        console.log("errorccAS" + error)
      }


    }



    _getList()
  }, []);


  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setSelectedMenu('Anasayfa');
        break;
      case '/news':
        setSelectedMenu('Haberler');
        break;
      case '/media':
        setSelectedMenu('Medya');
        break;
      default:
        if (location.pathname.startsWith('/mega')) {
          setSelectedMenu('Mega');
        } else if (location.pathname.startsWith('/project')) {
          setSelectedMenu('Projeler');
        }
        break;
    }
  }, [location]);

  const _getData = () => {
    setLoginData(JSON.parse(localStorage.getItem('dataLogin')))
  }

  return (
    isMobile == true ? <div className="w-full h-20 fixed top-0 z-50 bg-white shadow-lg">


      <div className=" flex justify-between items-center w-full h-full  ">
        <div className="grid grid-rows-2 w-full h-full border border-t-8 border-b-0">
          <div className="flex items-center justify-between ">
            <Link className="flex items-center justify-center z-30  " to={"/"}>
              <img src={Logo} alt="/" className="xxs:h-10 sm:h-14 md:h-16 lg:h-20 mt-10  ml-6   " />
            </Link>


            <div className="px-10 mt-10 md:hidden flex " onClick={handleNav}>
              {!nav ? (
                <AiOutlineClose color={'#2E7E9E'} size={23} />
              ) : (
                <AiOutlineMenu color={'#2E7E9E'} size={23} />
              )}
            </div>
            <div className="z-10 hidden md:flex justify-center items-center">

              {loginData == null ?
                <div className="z-10 w-full   relative bottom-0 mt-5 flex  justify-between rounded-l-md top-8">
                  <div className="flex ">

                    <Link className="border-r border-[#006890] w-28" to="/">
                      <div class=" relative p-2 flex flex-col">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#006890] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faHome} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium] text-[#006890]">Anasayfa</p>

                        </button>

                      </div>
                    </Link>



                    <Link className=" border-r  border-[#EB738A] w-28" to="#">
                      <div class=" relative p-2 flex flex-col  dropdown inline-block  ">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#EB738A] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faInfo} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium] text-[#EB738A]">MEGA</p>

                        </button>
                        <ul class="dropdown-menu absolute hidden bg-[#EB738A] left-0  w-full mt-16">

                          {getMega != null ? getMega.map((item) => {
                            return (
                              <li class="border-b-2"><Link class="rounded-t text-white hover:text-[#EB738A] hover:bg-[white] py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to={"/mega/" + item.ID} state={{ _item: item }} >{item.Mega_Title}</Link></li>
                            )
                          }) : null}
                        </ul>
                      </div>


                    </Link>


                   { getProject.length > 0 ? <Link className=" border-r  border-[#943A4A] w-28" to="#">
                      <div class=" relative p-2 flex flex-col  dropdown  ">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#943A4A] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faCertificate} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium] text-[#943A4A]">PROJELER</p>

                        </button>
                        <ul class="dropdown-menu absolute hidden bg-[#943A4A] w-full left-0  mt-16">

                          {getProject != null ? getProject.map((item) => {
                            return (
                              <li class="border-b-2">
                                <Link class="rounded-t text-white hover:text-[#943A4A] hover:bg-white py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to={"/project/" + item.ID} state={{ _item: item }}  >{item.Project_Title}</Link></li>
                            )
                          }) : null}
                        </ul>
                      </div>


                    </Link> : null}

                    <Link className="border-r  border-[#2E7E9E] w-28" to="/news">

                      <div class=" relative p-2 flex flex-col">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#2E7E9E] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faBullhorn} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium]  text-[#2E7E9E]">HABERLER</p>

                        </button>

                      </div>

                    </Link>
                    <Link className="border-r  border-[#EB738A] w-28" to="/media">

                      <div class=" relative p-2 flex flex-col">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#EB738A] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faImage} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium]  text-[#EB738A]">MEDYA</p>

                        </button>

                      </div>

                    </Link>

                    <Link className="border-r  border-[#5BC2EB] w-28" to="/iletisim">

                      <div class=" relative p-2 flex flex-col">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#5BC2EB] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faPhone} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium] text-[#5BC2EB]">İletişim</p>

                        </button>

                      </div>

                    </Link>



                  </div>

                  <div class="   p-2 relative">
                    <Link className="flex   w-28 flex-col items-center justify-center" to="https://kullanici.meramgelisim.com"
                      target="_blank" >
                      <div className="rounded-full bg-[#2CDBBE] w-8 h-8 flex items-center justify-center shadow-l ">
                        <FontAwesomeIcon icon={faRightToBracket} color={'white'} />
                      </div>
                      <p className="font-[Lato-Medium] text-[#2CDBBE] ">Üye Giriş</p>
                    </Link>

                  </div>

                  
                  <div class="   p-2 relative">
                    <a className="flex   w-28 flex-col items-center justify-center" href="https://www.meramgelisim.com/bilfest/bilfest.html"
                      target="_blank" >
                      <div className="rounded-full bg-[#2CDBBE] w-8 h-8 flex items-center justify-center shadow-l ">
                        <FontAwesomeIcon icon={faRightToBracket} color={'white'} />
                      </div>
                      <p className="font-[Lato-Medium] text-[#2CDBBE] ">MEGA BİLFEST 3</p>
                    </a>

                  </div>

                </div>
                :
                <div className="z-10 w-full h-12   relative bottom-0 mt-5 flex  justify-center top-12">
                  <ul className="flex items-end  z-10">

                    <Link className="border-r border-[#006890] w-28" to="/">
                      <div class=" relative p-2 flex flex-col">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#006890] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faHome} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium] text-[#006890]">Anasayfa</p>

                        </button>

                      </div>
                    </Link>



                    <Link className=" border-r  border-[#EB738A] w-28" to="#">
                      <div class=" relative p-2 flex flex-col  dropdown inline-block  ">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#EB738A] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faInfo} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium] text-[#EB738A]">MEGA</p>

                        </button>
                        <ul class="dropdown-menu absolute hidden bg-[#EB738A] left-0  w-full mt-16">

                          {getMega != null ? getMega.map((item) => {
                            return (
                              <li class="border-b-2"><Link class="rounded-t text-white hover:text-[#EB738A] hover:bg-[white] py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to={"/mega/" + item.ID} state={{ _item: item }} >{item.Mega_Title}</Link></li>
                            )
                          }) : null}
                        </ul>
                      </div>


                    </Link>


                    { getProject.length > 0 ?     <Link className=" border-r  border-[#943A4A] w-28" to="#">
                      <div class=" relative p-2 flex flex-col  dropdown  ">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#943A4A] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faCertificate} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium] text-[#943A4A]">PROJELER</p>

                        </button>
                        <ul class="dropdown-menu absolute hidden bg-[#943A4A] w-full left-0  mt-16">

                          {getProject != null ? getProject.map((item) => {
                            return (
                              <li class="border-b-2">
                                <Link class="rounded-t text-white hover:text-[#943A4A] hover:bg-white py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to={"/project/" + item.ID} state={{ _item: item }}  >{item.Project_Title}</Link></li>
                            )
                          }) : null}
                        </ul>
                      </div>


                    </Link> : null }

                    <Link className="border-r  border-[#2E7E9E] w-28" to="/news">

                      <div class=" relative p-2 flex flex-col">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#2E7E9E] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faBullhorn} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium]  text-[#2E7E9E]">HABERLER</p>

                        </button>

                      </div>

                    </Link>
                    <Link className="border-r  border-[#EB738A] w-28" to="/media">

                      <div class=" relative p-2 flex flex-col">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#EB738A] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faImage} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium]  text-[#EB738A]">MEDYA</p>

                        </button>

                      </div>

                    </Link>

                    <Link className="border-r  border-[#5BC2EB] w-28" to="/iletisim">

                      <div class=" relative p-2 flex flex-col">
                        <button className="flex  flex-col items-center justify-center" >
                          <div className="rounded-full bg-[#5BC2EB] w-8 h-8 flex items-center justify-center shadow-l ">
                            <FontAwesomeIcon icon={faPhone} color={'white'} />
                          </div>

                          <p className="font-[Lato-Medium] text-[#5BC2EB]">İletişim</p>

                        </button>

                      </div>

                    </Link>



                    <div class="border-r border-[#EBE244] w-28  relative p-2 flex flex-col">
                      <button onClick={() => openProfile(true)} className="flex  flex-col items-center justify-center" >
                        <div className="rounded-full bg-[#EBE244] w-8 h-8 flex items-center justify-center shadow-l ">
                          <FontAwesomeIcon icon={faUser} color={'white'} />
                        </div>

                        <p className="font-[Lato-Medium]  text-[#EBE244]">Profilim</p>

                      </button>

                    </div>


                    {loginData != null ?


                      <li
                        className=" hover:bg-[#006890] w-28" >


                        <div class=" relative p-2 flex flex-col">
                          <button onClick={() => _deleteAuth()} className="flex  flex-col items-center justify-center" >
                            <div className="rounded-full bg-[red] w-8 h-8 flex items-center justify-center shadow-l ">
                              <FontAwesomeIcon icon={faRightFromBracket} color={'white'} />
                            </div>

                            <p className="font-[Lato-Medium]  text-[red]">Çıkış</p>

                          </button>

                        </div>


                      </li>


                      : null}
                  </ul>

                </div>
              }





            </div>


            <div
              className={
                !nav
                  ? " fixed left-0 top-0 w-[60%] md:w-[20%] h-full  border-r bg-white  z-40 ease-in-out duration-300 "
                  : "z-10 fixed left-[100%] "
              }
            >

              <div className="border-b-2  border-b-white bg-white flex justify-center items-center ">
                <Link to={"/"} >
                  <img
                    src={whiteLogo}
                    alt="/"
                    className=" p-5 "
                  />


                </Link>
              </div>
              {/* MOBİL MENÜ */}



              <Link className=" flex justify-center items-center  bg-[#006890] border-b-2  border-b-white" to="/">

                <a
                  className="rounded-lg text-white  font-[Lato-Medium]  hover:bg-forthcolor hover:text-white text-center p-2">
                  ANASAYFA
                </a>

              </Link>




              <Link className="flex justify-center items-center  bg-[#EB738A]  border-b-2 border-b-white" to="#">
                <div class=" relative p-2 flex flex-col  dropdown inline-block  ">
                  <button className="flex  flex-col items-center justify-center" >

                    <p className="font-[Lato-Medium] text-white">MEGA</p>

                  </button>
                  <ul class="dropdown-menu absolute hidden bg-[#EB738A]   ">

                    {getMega != null ? getMega.map((item) => {
                      return (
                        <li class="border-b-2"><Link class="rounded-t text-white hover:text-[#EB738A] hover:bg-[white] py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to={"/mega/" + item.ID} state={{ _item: item }} >{item.Mega_Title}</Link></li>
                      )
                    }) : null}
                  </ul>
                </div>


              </Link>

              { getProject.length > 0 ? <Link className="flex justify-center items-center  bg-[#943A4A]  border-b-2 border-b-white" to="#">
                <div class=" relative p-2 flex flex-col  dropdown  ">

                  <button className="flex  flex-col items-center justify-center" >

                    <p className="font-[Lato-Medium] text-white">PROJELER</p>

                  </button>

                  <ul class="dropdown-menu absolute hidden bg-[#943A4A]  ">

                    {getProject != null ? getProject.map((item) => {
                      return (
                        <li class="border-b-2">
                          <Link class="rounded-t text-white hover:text-[#943A4A] hover:bg-white py-2 px-4 block whitespace-no-wrap  font-[Lato-Bold]" to={"/project/" + item.ID} state={{ _item: item }}  >{item.Project_Title}</Link></li>
                      )
                    }) : null}
                  </ul>
                </div>


              </Link> : null }

              <Link to="/news" className="flex justify-center items-center bg-[#006890]  border-b-2  border-b-white">

                <a
                  className="rounded-lg text-white  font-[Lato-Medium]  hover:bg-forthcolor hover:text-white text-center p-2">
                  HABERLER
                </a>

              </Link>

              <Link to="/media" className="flex justify-center items-center  bg-[#EB738A] border-b-2 border-b-white" >

                <a
                  className="rounded-lg text-white  font-[Lato-Medium]  hover:bg-forthcolor hover:text-white text-center p-2">
                  MEDYA
                </a>

              </Link>


              <Link to="/iletisim" className="flex justify-center items-center bg-[#5BC2EB] border-b-2  border-b-white" >

                <a
                  className="rounded-lg text-white  font-[Lato-Medium] w-full  hover:bg-forthcolor hover:text-white text-center p-2">
                  İLETİŞİM
                </a>

              </Link>
              <Link to="https://kullanici.meramgelisim.com"
                target="_blank" className="flex justify-center items-center bg-[#2CDBBE] border-b-2  border-b-white" >

                <a
                  className="rounded-lg text-white  font-[Lato-Medium] w-full  hover:bg-forthcolor hover:text-white text-center p-2">Üye Giriş</a>
              </Link>

              {loginData != null ?

                <button className="bg-[#006890] text-white w-full flex justify-center items-center" to="/jobsJobSeeker" onClick={() => _deleteAuth(false)}>
                  <p className="font-[Lato-Medium] p-2">ÇIKIŞ</p>

                </button>
                : null}

              {/* MOBİL MENÜ bitiş*/}


            </div>



          </div>



        </div>
      </div>

      <Autharizatiom _closeLoginOpenSingUpModal={_closeLoginOpenSingUpModal} openAuthModalVisible={logInModal} _closeAuthModal={_closeAuthModal} handleLogIn={handleLogIn} />

      <Profile _getProfileVisible={getProfileVisible} _closeProfileModal={_closeProfileModal} />

    </div>



      :

     
<div className="w-full h-20 fixed top-0 z-50 bg-white shadow-lg">
  <div className="container mx-auto flex justify-between items-center h-full px-4">

    {/* Logo */}
    <div className="text-2xl font-bold text-[#006890]">
      <Link to="/">
        <img src={Logo} alt="Logo" className="h-12 w-auto mt-4 ml-6" />
      </Link>
    </div>

    {/* Desktop Menu */}
    <div className="hidden md:flex space-x-4 items-center flex-wrap"> {/* flex-wrap eklendi */}
      <Link to="/" className={`text-lg ${selectedMenu === 'Anasayfa' ? 'font-bold' : 'text-[#006890]'}  font-[Lato-Bold] hover:text-blue-700`} onClick={() => setSelectedMenu('Anasayfa')}>
        Anasayfa
      </Link>

      {/* Mega Menü */}
      <div className="relative group">
        <button className={`text-lg ${selectedMenu === 'Mega' ? 'font-bold' : 'text-[#006890]'} hover:text-pink-700 font-[Lato-Medium]`} onClick={() => setSelectedMenu('Mega')}>
          Mega
        </button>
        <div className="absolute hidden group-hover:block bg-white shadow-lg rounded-lg p-4 top-full left-0 w-[300px]">
          {getMega && getMega.map((item, index) => (
            <Link key={index} to={"/mega/" + item.ID} state={{ _item: item }} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  font-[Lato-Medium]">
              {item.Mega_Title}
            </Link>
          ))}
        </div>
      </div>

      {/* Projeler Menüsü */}
      <div className="relative group">
        <button className={`text-lg ${selectedMenu === 'Projeler' ? 'font-bold' : 'text-[#006890]'} hover:text-blue-700  font-[Lato-Medium]`} onClick={() => setSelectedMenu('Projeler')}>
          Projeler
        </button>
        <div className="absolute hidden group-hover:block bg-white shadow-lg rounded-lg p-4 top-full left-0 w-[300px]">
          {getProject && getProject.map((item, index) => (
            <Link key={index} to={"/project/" + item.ID} state={{ _item: item }} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  font-[Lato-Medium]">
              {item.Project_Title}
            </Link>
          ))}
        </div>
      </div>

      <Link to="/news" className={`text-lg ${selectedMenu === 'Haberler' ? 'font-bold' : 'text-[#006890]'} hover:text-blue-700  font-[Lato-Medium]`} onClick={() => setSelectedMenu('Haberler')}>
        Haberler
      </Link>

      <Link to="/media" className={`text-lg ${selectedMenu === 'Medya' ? 'font-bold' : 'text-[#006890]'} hover:text-blue-700  font-[Lato-Medium]`} onClick={() => setSelectedMenu('Medya')}>
        Medya
      </Link>

      <a className="text-lg text-[#2CDBBE] hover:text-blue-700  font-[Lato-Bold]" href="https://www.meramgelisim.com/bilfest/bilfest.html" target="_blank">
        MEGA BİLFEST 3
      </a>

      <Link to="/PdfPage" className={`text-lg ${selectedMenu === 'İletişim' ? 'font-bold' : 'text-[red]'} hover:text-blue-700  font-[Lato-Medium]`} onClick={() => setSelectedMenu('İletişim')}>
        Dergi
      </Link>

      <Link to="https://kullanici.meramgelisim.com" target="_blank" className={`text-lg ${selectedMenu === 'Üye Girişi' ? 'font-bold' : 'text-[#EB738A]'} hover:text-blue-700  font-[Lato-Medium]`} onClick={() => setSelectedMenu('Üye Girişi')}>
        Üye Girişi
      </Link>

      <Link to="https://kullanici.meramgelisim.com/pages-register-2" target="_blank" className={`text-lg text-[#006890] hover:text-blue-700  font-[Lato-Medium]`}>
        Kayıt Ol
      </Link>

      <Link to="/iletisim" className={`text-lg ${selectedMenu === 'İletişim' ? 'font-bold' : 'text-[#006890]'} hover:text-blue-700  font-[Lato-Medium]`} onClick={() => setSelectedMenu('İletişim')}>
        İletişim
      </Link>

    </div>

    {/* Hamburger Menu (Mobile) */}
    <div className="md:hidden">
      <button onClick={toggleNav} className="text-3xl text-[#006890]">
        {navOpen ? <HiX /> : <HiMenuAlt3 />}
      </button>
    </div>

    {/* Mobile Menu */}
    <div className={`fixed left-0 top-0 w-full h-screen bg-black/70 transform ${navOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 md:hidden`}>
      <div className="fixed left-0 top-0 w-[75%] sm:w-[60%] md:w-[45%] h-full bg-white p-6 shadow-lg">
        <button onClick={toggleNav} className="text-2xl font-bold text-[#006890] mb-8">X</button>
        <nav className="space-y-6">
          <Link to="/" onClick={toggleNav} className="block text-xl text-[#006890] hover:text-blue-700">Anasayfa</Link>

          {/* Mega Menü (Mobil) */}
          <div>
            <button className="text-xl text-[#EB738A] hover:text-pink-700">Mega</button>
            <div className="pl-4 mt-2">
              {getMega && getMega.map((item, index) => (
                <Link key={index} to={"/mega/" + item.ID} onClick={toggleNav} className="block py-1 text-gray-700 hover:bg-gray-100">
                  {item.Mega_Title}
                </Link>
              ))}
            </div>
          </div>

          {/* Projeler Menü (Mobil) */}
          <div>
            <button className="text-xl text-[#006890] hover:text-blue-700">Projeler</button>
            <div className="pl-4 mt-2">
              {getProject && getProject.map((item, index) => (
                <Link key={index} to={"/project/" + item.ID} onClick={toggleNav} className="block py-1 text-gray-700 hover:bg-gray-100">
                  {item.Project_Title}
                </Link>
              ))}
            </div>
          </div>

          <Link to="/iletisim" onClick={toggleNav} className="block text-xl text-[#006890] hover:text-blue-700">İletişim</Link>
        </nav>
      </div>
    </div>
  </div>
</div>

 
  );
}));

export default Navbar;

